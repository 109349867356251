// extracted by mini-css-extract-plugin
export var BannerModuleGridRmb5c = "Banner-module--Banner-module--grid--Rmb5c--APhd+";
export var bannerText = "Banner-module--bannerText--449M1";
export var bxTextTruncateEnd = "Banner-module--bx--text-truncate--end--Kph+h";
export var bxTextTruncateFront = "Banner-module--bx--text-truncate--front--mc2e7";
export var column = "Banner-module--column--EmDch";
export var container = "Banner-module--container--ggDH3";
export var containerHomepage = "Banner-module--container--homepage--7xG5M";
export var grid = "Banner-module--grid--Rmb5c";
export var mainContent = "Banner-module--main-content--0gepE";
export var row = "Banner-module--row--exiKA";