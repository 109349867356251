import HomepageTile from "../../../../src/components/HomepageTile";
import { withPrefix } from "gatsby";
import nigelFeatureImg from "../../../../src/pages/podcast/images/card-nigel.png";
import episodesFeatureImg from "../../../../src/pages/podcast/images/episodes-banner.png";
import * as React from 'react';
export default {
  HomepageTile,
  withPrefix,
  nigelFeatureImg,
  episodesFeatureImg,
  React
};