import HomepageTemplate from "gatsby-theme-carbon/src/templates/Homepage";
import HomepageTile from "../../../../src/components/HomepageTile";
import FullBleedContainer from "../../../../src/components/FullBleedContainer";
import { withPrefix } from "gatsby";
import "../../../../src/pages/index.scss";
import featureCallToActionImg from "../../../../src/pages/images/featured-bdppf.png";
import episodesFeatureImg from "../../../../src/pages/images/episodes-banner.png";
import MissionStatement from "../../../../src/components/MissionStatement";
import * as React from 'react';
export default {
  HomepageTemplate,
  HomepageTile,
  FullBleedContainer,
  withPrefix,
  featureCallToActionImg,
  episodesFeatureImg,
  MissionStatement,
  React
};