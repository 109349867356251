import React, { useLayoutEffect, useState } from 'react';
import { Row, Column } from 'gatsby-theme-carbon';
import PropTypes from 'prop-types';
import cx from 'classnames';
import HomepageTile from '../HomepageTile/HomepageTile';
import { withPrefix } from "gatsby";
import * as styles from './homepage-tile-container.module.scss';

const HomepageTileContainer = ({ title, name, text, image, linkTitle, link, children }) => (
  <>
    <Row>
      <Column colLg={3} className={cx(`left-featured`)}>
        <h3 class={cx(`left-featured--title`)}>{title}</h3>

        <div className={cx(`left-featured--text`)}>
          {name ? <strong>{name}</strong> : null}
          {name ? <br /> : null}
          {text}
        </div>
      </Column>
      <Column colLg={9} className={cx(`right-featured`)}>
        <HomepageTile
          image={image}
          ratio={{ default: "1:1", md: "16:9", lg: "16:9" }}
          actionIcon="no-icon"
          marginTop="1rem"
        >
          <HomepageTile
            ratio={{ default: "2:1", md: "6:1", lg: "6:1" }}
            theme="dark"
            title={linkTitle}
            link={withPrefix(link)}
            actionIcon="arrowRight"
            hoverColor="#0043ce"
          />
        </HomepageTile>
        {children}
      </Column>
    </Row>
  </>
);

HomepageTileContainer.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.node,
  linkTitle: PropTypes.string,
  link: PropTypes.string,
  children: PropTypes.node,
};

export default HomepageTileContainer;