import React from 'react';
import { Row, Column } from 'gatsby-theme-carbon';
import { settings } from 'carbon-components';
import PropTypes from 'prop-types';
import { rowSpacing, image, column, container, quoteText, authorText, roleText } from './pull-quote.module.scss';

const { prefix } = settings;

const PullQuote = ({ children, quote, author, role }) => (
  <Row className={rowSpacing}>
    <Column colMd={8} colLg={6} className={image}>
      <div>{children}</div>
    </Column>
    <Column colMd={8} colLg={6} className={column}>
      <div className={`${prefix}--aspect-ratio ${prefix}--aspect-ratio--1x1`}>
        <div className={`${prefix}--aspect-ratio--object`}>
          <div className={container}>
            <p className={quoteText}>{quote}</p>
            <p className={authorText}>{author}</p>
            <p className={roleText}>{role}</p>
          </div>
        </div>
      </div>
    </Column>
  </Row>
);

PullQuote.propTypes = {
  children: PropTypes.node,
  quote: PropTypes.string,
  author: PropTypes.string,
  role: PropTypes.string,
};

export default PullQuote;
