import "../../../../src/pages/career-success/career-success.scss";
import { withPrefix } from "gatsby";
import interviewImg from "../../../../src/pages/career-success/images/interview/Interview.png";
import portfolioImg from "../../../../src/pages/career-success/images/portfolio/Portfolio.png";
import caseStudiesImg from "../../../../src/pages/career-success/images/showcase/Showcase.png";
import designSpecialtiesImg from "../../../../src/pages/career-success/images/Design-specialties.png";
import applyImg from "../../../../src/pages/career-success/images/apply_group.png";
import SoloQuote from "../../../../src/components/SoloQuote/SoloQuote";
import HomepageTile from "../../../../src/components/HomepageTile";
import HomepageTileContainer from "../../../../src/components/HomepageTileContainer/HomepageTileContainer";
import * as React from 'react';
export default {
  withPrefix,
  interviewImg,
  portfolioImg,
  caseStudiesImg,
  designSpecialtiesImg,
  applyImg,
  SoloQuote,
  HomepageTile,
  HomepageTileContainer,
  React
};