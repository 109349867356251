import React from 'react';
import { Row, Column } from 'gatsby-theme-carbon';
import PropTypes from 'prop-types';
import {details_image_row, image_title_column,image_column, titleText, subtitleText, detailsText} from './podcast-details-image.module.scss';

const PodcastDetailsImage = ({ title, subtitle, details, children }) => (
  <Row className={details_image_row}>
    <Column className={image_title_column} colLg={6}>
      <h3 className={titleText}>{title}</h3>
      <h4 className={subtitleText}>{subtitle}</h4>
      <div className={detailsText}>{details}</div>
    </Column>
    <Column className={image_column} colLg={6}>
      <div>{children}</div>
    </Column>
  </Row>
);

PodcastDetailsImage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  details: PropTypes.string,
  children: PropTypes.node,
};

export default PodcastDetailsImage;
