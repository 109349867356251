import React from 'react';
import { Row, Column } from 'gatsby-theme-carbon';
import PropTypes from 'prop-types';
import { dateText, guestText, roleText, episodeText, description } from './podcast-entry.module.scss';

const PodcastEntry = ({ date, guest, role, children, episode }) => (
  <Row>
    <Column colLg={3}>
      <h3 className={dateText}>{date}</h3>
      <div className={guestText}>Guest: {guest}</div>
      <div className={roleText}>{role}</div>
    </Column>
    <Column colLg={6}>
      <h2 className={episodeText}>Episode {episode}</h2>
      <div className={description}>{children}</div>
    </Column>
  </Row>
);

PodcastEntry.propTypes = {
  date: PropTypes.string,
  guest: PropTypes.string,
  role: PropTypes.string,
  children: PropTypes.node,
  episode: PropTypes.string,
};

export default PodcastEntry;
