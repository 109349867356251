import "../../../../src/pages/podcast/podcast.scss";
import backGroundImage from "../../../../src/pages/podcast/images/banner-podcast.png";
import PodcastEntry from "../../../../src/components/PodcastEntry";
import PodcastDetailsImage from "../../../../src/components/PodcastDetailsImage";
import PodcastDetailsQuote from "../../../../src/components/PodcastDetailsQuote";
import * as React from 'react';
export default {
  backGroundImage,
  PodcastEntry,
  PodcastDetailsImage,
  PodcastDetailsQuote,
  React
};