import "../../../../src/pages/the-work/equity-first-workplace.scss";
import { withPrefix } from "gatsby";
import featureWorkplaceImg from "../../../../src/pages/the-work/images/equity-first-workplace/workplace_image.png";
import HomepageTile from "../../../../src/components/HomepageTile";
import * as React from 'react';
export default {
  withPrefix,
  featureWorkplaceImg,
  HomepageTile,
  React
};