import classnames from 'classnames';
import { bool, node, string } from 'prop-types';
import React from 'react';

const namespace = 'container--full-bleed';

const FullBleedContainer = ({ children, className, flush, ...rest }) => (
  <div
    className={classnames(namespace, className, {
      [`${namespace}--flush`]: flush,
    })}
    {...rest}
  >
    {children}
  </div>
);

FullBleedContainer.propTypes = {
  children: node.isRequired,
  flush: bool,
  className: string,
};

FullBleedContainer.defaultProps = {
  flush: false,
  className: null,
};

export default FullBleedContainer;
