import React from 'react';
import { Row, Column } from 'gatsby-theme-carbon';
import PropTypes from 'prop-types';
import { guestText, titleText, row, quote } from './podcast-details-quote.module.scss';

const PodcastDetailsQuote = ({ title, guest, children }) => (
  <Row className={row}>
    <Column colLg={3}>
      <h3 className={titleText}>{title}</h3>
    </Column>
    <Column colLg={6}>
      <h2 className={quote}>{children}</h2>
      <div className={guestText}>{guest}</div>
    </Column>
  </Row>
);

PodcastDetailsQuote.propTypes = {
  title: PropTypes.string,
  quote: PropTypes.string,
  guest: PropTypes.string,
};

export default PodcastDetailsQuote;
