import "../../../../src/pages/career-success/career-success.scss";
import { withPrefix } from "gatsby";
import SoloQuote from "../../../../src/components/SoloQuote/SoloQuote";
import ImageAccordion from "../../../../src/components/ImageAccordion/ImageAccordion";
import VideoContainer from "../../../../src/components/VideoContainer/VideoContainer";
import * as React from 'react';
export default {
  withPrefix,
  SoloQuote,
  ImageAccordion,
  VideoContainer,
  React
};