import React from 'react';
import { Row, Column } from 'gatsby-theme-carbon';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as styles from './video-container.module.scss';

const VideoContainer = ({ 
  title, 
  descriptionTop,
  descriptionBottom,
  children 
}) => {

  return (
    <>
      <Row>
        <Column colLg={3} className={cx(`left-featured`)}>
          <h3 class={cx(`left-featured--title`)}>{title}</h3>
        </Column>
        <Column colLg={6} className={cx(`right-featured`, description_column)}>
          <div className={description}>{descriptionTop}</div>
          <div>
          {children}
          </div>
          <div className={video_description}>{descriptionBottom}</div>
        </Column>
      </Row>
    </>
  );
};

VideoContainer.PropTypes = {
  title: PropTypes.string,
  descriptionTop: PropTypes.string,
  descriptionBottom: PropTypes.string,
  children: PropTypes.node,
}

export default VideoContainer;