import { withPrefix } from "gatsby";
import featureFieldGuideImg from "../../../../src/pages/the-work/images/field-guide-for-leadership/FieldGuide_tile_image.png";
import HomepageTile from "../../../../src/components/HomepageTile";
import { useGoal } from "gatsby-plugin-fathom";
import * as React from 'react';
export default {
  withPrefix,
  featureFieldGuideImg,
  HomepageTile,
  useGoal,
  React
};