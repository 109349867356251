import React from 'react';
import { Row, Column } from 'gatsby-theme-carbon';
import PropTypes from 'prop-types';
import { quote_container } from './solo-quote.module.scss';
import cx from 'classnames';

const SoloQuote = ({ description, children, author, quote, className }) => (
  <Row className={cx(quote_container, className)}>
    <Column colLg={6} offsetLg={3}>
      <div className={description}>{description}</div>
      <div className={quote}>{quote}</div>
      <div className={author}>{author}</div>
      {children}
    </Column>
  </Row>
);

SoloQuote.propTypes = {
  description: PropTypes.string,
  children: PropTypes.string,
  author: PropTypes.string,
  className: PropTypes.string,
  quote: PropTypes.string,
};

export default SoloQuote;