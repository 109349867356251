import React from 'react';
import { HomepageBanner, HomepageCallout } from 'gatsby-theme-carbon';
import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
import Carbon from '../../pages/images/will-hero.png';

const SecondLeftText = () => <p>&nbsp;</p>;
const SecondRightText = () => <p>&nbsp;</p>;
const BannerText = () => <h1>Racial Equity in Design</h1>;

const customProps = {
    Banner: <HomepageBanner renderText={BannerText} image={Carbon} />,
    FirstCallout: <></>,
    SecondCallout: (
      <HomepageCallout
        className="hidden"
        leftText={SecondLeftText}
        rightText={SecondRightText}
        color="white"
        backgroundColor="#061f80"
      />
    ),
  };

// spreading the original props gives us props.children (mdx content)
function ShadowedHomepage(props) {
  return <HomepageTemplate {...props} {...customProps} />;
}

export default ShadowedHomepage;
