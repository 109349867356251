import React from 'react';
import { Row, Column } from 'gatsby-theme-carbon';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { settings } from 'carbon-components';
const { prefix } = settings;
import * as styles from './image-accordion.module.scss';

const ImageAccordion = ({ 
  title, 
  descriptionTop,
  descriptionBottom, 
  image, 
  backgroundColor,
  children 
}) => {
  const backgroundStyle = {
    backgroundColor: backgroundColor || null,
    backgroundImage: image ? `url(${image})` : null,
  };

  return (
    <>
      <hr className={cx(`dark-theme--hr`, styles.hr)} />
      <Row>
        <Column colLg={3} className={cx(`left-featured`)}>
          <h3 class={cx(`left-featured--title`)}>{title}</h3>
        </Column>
        <Column colLg={9} className={cx(`right-featured`)}>
          {descriptionTop ? <div className={styles.descriptionTop}>{descriptionTop}</div> : null}
          <img className={styles.image} src={image} />
          <div className={styles.descriptionBottom}>{descriptionBottom}</div>
          {children}
        </Column>
      </Row>
    </>
  );
};

ImageAccordion.PropTypes = {
  title: PropTypes.string,
  descriptionTop: PropTypes.string,
  descriptionBottom: PropTypes.string,
  image: PropTypes.string,
  children: PropTypes.node,
}

export default ImageAccordion;
