import "../../../../src/pages/career-success/career-success.scss";
import { withPrefix } from "gatsby";
import designSpecialtiesImg from "../../../../src/pages/career-success/images/Design-specialties.png";
import SoloQuote from "../../../../src/components/SoloQuote/SoloQuote";
import * as React from 'react';
export default {
  withPrefix,
  designSpecialtiesImg,
  SoloQuote,
  React
};