import "../../../../src/pages/career-success/career-success.scss";
import { withPrefix } from "gatsby";
import SoloQuote from "../../../../src/components/SoloQuote/SoloQuote";
import ImageAccordion from "../../../../src/components/ImageAccordion/ImageAccordion";
import IconDescription from "../../../../src/components/IconDescription/IconDescription";
import brain from "../../../../src/pages/career-success/images/icons/brain.svg";
import heart_recycle from "../../../../src/pages/career-success/images/icons/heart_recycle.svg";
import hr from "../../../../src/pages/career-success/images/icons/hr.svg";
import user from "../../../../src/pages/career-success/images/icons/user.svg";
import * as React from 'react';
export default {
  withPrefix,
  SoloQuote,
  ImageAccordion,
  IconDescription,
  brain,
  heart_recycle,
  hr,
  user,
  React
};