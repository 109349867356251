import React from 'react';
import { Row, Column } from 'gatsby-theme-carbon';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as styles from './icon-description.module.scss';

const IconDescription = ({ icon, description, alt, className }) => (
  <Row className={cx(styles.row, className)}>
    <Column className={styles.icon_column} colLg={2}>
      <img width="77" height="77" src={icon} alt={alt} />
    </Column>
    <Column colLg={4}>
      <div className={styles.description}>{description}</div>
    </Column>
  </Row>
);

IconDescription.propTypes = {
  icon: PropTypes.string,
  description: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default IconDescription;