import React, { useState, useLayoutEffect } from 'react';
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import { BackgroundImage } from 'react-image-and-background-image-fade';
import { column, row, grid, bannerText } from './Banner.module.scss';

import alisha from './images/banner-alisha.png';
import andrea from './images/banner-andrea.png';
import joelle from './images/banner-joelle.png';
import nigel from './images/banner-nigel.png';
import will from './images/banner-will.png';
import brad from './images/banner-brad.png';
import herman from './images/banner-herman.png';
import renee from './images/banner-renee.png';
import oen from './images/banner-oen.png';
import marcsene from './images/banner-marcsene.png';
import alex from './images/banner-alex.png';

const HomepageBanner = () => {
  const [person, setPerson] = useState({ name: '', role: '', image: '' });

  useLayoutEffect(() => {
    const personArray = [
      {
        name: 'Joelle Williams',
        role: 'Adoption and Impact Lead',
        image: joelle,
      },
      {
        name: 'Will Scott',
        role: 'Software Architect & Master Inventor',
        image: will,
      },
      {
        name: 'Alisha Padolsky',
        role: 'Visual and UX designer',
        image: alisha,
      },
      {
        name: 'Nigel Prentice',
        role: 'Design Director',
        image: nigel,
      },
      {
        name: 'Andrea Barbarin',
        role: 'Design researcher',
        image: andrea,
      },
      {
        name: 'Brad Neal',
        role: 'Design director',
        image: brad,
      },
      {
        name: 'Herman Colquhoun Jr.',
        role: 'IBM Design Principal, Quantum Integration',
        image: herman,
      },
      {
        name: 'Renee Albert',
        role: 'Program Lead, Design Learning',
        image: renee,
      },
      {
        name: 'Oen Hammonds',
        role: 'IBM Distinguished Designer, IBMer Experience',
        image: oen,
      },
      {
        name: 'Marcsene Torchon',
        role: 'Design researcher',
        image: marcsene,
      },
      {
        name: 'Alex Graves',
        role: 'Visual design researcher',
        image: alex,
      },
    ];
    const randomNumber = Math.floor(Math.random() * personArray.length);
    setPerson(personArray[randomNumber]);
  }, []);

  return (
    <BackgroundImage src={person.image} disableLoader lazyLoad useChild>
      <div className={`bx--grid ${grid}`}>
        <Row className={row}>
          <Column className={column}>
            <div className={bannerText}>
              <p className="bx--type-semibold">{person.name}</p>
              <p style={{ paddingBottom: '1rem' }}>{person.role}</p>
            </div>
          </Column>
        </Row>
      </div>
    </BackgroundImage>
  );
};

export default HomepageBanner;
