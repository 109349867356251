import React from 'react';
import { Row, Column } from 'gatsby-theme-carbon';
import PropTypes from 'prop-types';
import * as styles from './mission-statement.module.scss';

const PullQuote = ({ text }) => (
  <Row className={row}>
    <Column colMd={8} colLg={6} className={column}>
      <div className={text}>{text}</div>
    </Column>
  </Row>
);

PullQuote.propTypes = {
  children: PropTypes.node,
};

export default PullQuote;
